.fieldHeaderfordoctor {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: auto;
  max-width: 22%;
  border: 1px solid white;
  border-radius: 10px;
  height: 3.5vw;
}
.fieldHeader {
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
  margin-right: 5px;
  cursor: pointer;
}
.savedBillFieldHeader {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: flex-end;
  flex: 1;

 
}
.emed_DialogboxOrder {
  margin-top: 10px;
}
.Avatar {
  display: flex;
  align-items: center;
  right: 30px;
}
.eMed_user {
  width: 17.5vw;
  /* height: 2vw; */
}
.eMed_loginuser {
  font-size: 0.9vw;
}
.eMed_LoginType {
  font-size: 0.8vw;
}
.eMed_servicetype {
  width: 15vw;
}

.eMed_headContent {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.NotiContainer {
  position: absolute;
  top: 9%;
  left: 8%;
  width: 85vw;
  height: 6vw;
  z-index: 99;
}

.IdleNotiContainer {
  position: absolute;
  bottom: 3%;
  left: 8%;
  width: 85vw;
  height: 6vw;
  z-index: 99;
}

.NotiWrapper {
  background-color: #eb4b4b;
  border-radius: .2vw;
  padding: 8px;
  display: flex;
  align-items: center;
}
.IdleNotiWrapper {
  background-color: #ff7c00;
  border-radius: .2vw;
  padding: 8px;
  display: flex;
  align-items: center;
}

.NotiHeaderContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.NotiText{
  color: white;
  padding-left: .5vw;
  font-size: 1vw;
  font-weight: 400;
}

.NotiIcon {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: .3vw;
}

.NotiPara{
  color: white;
  font-size: 1vw;
  padding-left: 20vw;
}

.eMed_golbalSearch {
  justify-content: center;
  align-items: center;
  margin-top: -1px;
  margin-left: 10px;
}

.emed_headerLogo {
  width: 5vw;
}

.doctorName {
  font-size: 0.8vw;
  font-weight: bold;
}

.eMed_profile {
  width: 80%;
  margin-left: 0.625vw;
}

.eMed_Avatar{
  width: 20%;
}
.Icon {
  color: white;
}

.opht {
  font-size: 0.7vw;
}

.eMed_searchbox {
  background-color: white;
  border-radius: 4px;
  max-width: 20vw;
}

.img_logo {
  width: 3vw;
}

.img_logo1 {
  height: 60%;
  width: 80%;
}

.label {
  font-size: 0.6vw;
  cursor: pointer;
}

.sublabel {
  font-size: 0.6vw;
}

.MenuDropdown {
  margin-left: 20px;
  font-size: 30px;
}

#emed_sidebarmenu {
  font-size: 12px;
}

.emed_Dialogboxwidth {
  padding: 15px;
  width: 26vw;
  height: 49vh;
}
.emed_Dialog_Pres {
  padding: 1vw;
  width: 30vw;
  height: 70vh;
}
.emed_Dialog_Appoin {
  padding: 1vw;
  width: 30vw;
  height: 74vh;
}
.emed_Dia_SaveBill {
  padding: 1vw;
  width: 40vw;
  height: 70vh;
}

/* .eMed_Drawer ::-webkit-scrollbar {
  display: none;
} */

.eMed_Drawer {
  max-width: 2.3vw;
}

.eMed_AvatarMenu {
  width: 15vw;
}

.eMed_searchbarIcon {
  cursor: pointer;
}

#eMed_menuHieghlight {
  margin: 10px;
  padding-left: 30px;
  border-radius: 4%;
}

.eMed_muiDrawer {
  display: flex;
  flex-grow: 1px;
  padding-top: 4.3vw;
  height: 95vh;
}

#eMed_profileMenu {
  margin-top: 50px;
  margin-left: -1vw;
}

.eMed_menuIcon {
  margin-left: 0px;
}

.eMed_menuText {
  margin-left: -8px;
  font-size: 10px;
}

.eMed_subMenus {
  display: flex;
}

.eMed_muiModulepart {
  flex-grow: 1px;
  min-width: 94vw;
  padding-left: 10px;
  padding-right: 10px;
}

.eMed_menuItems {
  cursor: pointer;
  /* border-radius: 20%; */
}

#eMed_side_list {
  margin-top: 1vw;
  display: flex;
  flex-direction: column;
  align-items: center;

}

.eMed_appbar_menu {
  display: flex;
  width: 60vw;
  flex-direction: row;
  justify-content: flex-end;
}

#eMed_menu_icons {
  font-size: 2vw;
}
 @media (min-width: 600px) {
  .MuiToolbar-root {
    min-height: 4vw !important;
  }
} 
/*@media (min-width: 600px) {
  .css-13r55hi {
    min-height: 4vw !important;
  }
}*/
@media (min-width: 600px) {
  .MuiDrawer-docked 
  .MuiDrawer-paper{
  width: 4.8vw !important;
}
}
.MuiListItemIcon-root{
  min-width: 2.5vw !important;
  margin-left: -0.5vw !important;
}
.lab_saved_bill_icon {
  align-items: center;
  border-width: 0vw;
  margin-top: 0vw;
  height: 3vw;
}
.fo_requests_icon {
  align-items: center;
  display: flex;
  flex-direction: column;
  border-width: 0vw;
  margin-top: 0vw;
  height: 3vw;
  margin-right: 0.9vw;
  width: 4.2vw;
}
.SavedBillImage {
  height: 5vh;
  width: 3vw;
}
.Lab_Home_Patient_Search {
  background-color: #04B7B1;
  border-radius: 0.3vw;
  margin-left: 4vw;
}
.Patient_Details_Search{
  z-index: 1500;
  position: absolute;
  background-color: #F5F8F7;
  height: 40vh;
  width: 24vw;
  overflow: scroll;
  padding: 0.5vw;
  flex: 1;
  border-radius: 0.3vw;
  box-shadow: 0px 4px 8px 0px gray;
}
.search_sub_container {
  display: flex;
  flex-direction: row;
  flex: 0.6;
  margin: 0vw;
  padding: 0vw;
}
.Patient_Details_Main {
  height: 10vh;
  display: flex;
  flex-direction: row;
  flex: 1;
  align-items: center;
}
.Lab_Search_Button {
  justify-content: center;
  align-items: center;
  align-self: center;
  height: 5vh;
  min-width: 13% ;
  margin-top: 0vw;
}
.Patient_Search_Text {
   color: lightgray;
   margin-left: 0.5vw;
  
}
.placeholderStyle {
  font-size: 0.8vw;
}

.mui_header_card_right{
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left:3vw;
  background-color: #E4F2F0;
  border-radius: 0.2vw;
}

.mui_header_card_dropdown{
  height: 4.7vh;
}
.mui_header_card_dropdown_fo{
  height:2.56vw;
}

.eMed_Header_Search_item{
  display: flex;
  flex-direction: row;
  width: 35vw;
  justify-content: space-between;
  align-items: center;
  padding-left:1vw ;
  padding-right:1vw ;
  height: 4vw;
}
.eMed_Header_Filter_Pop{
  margin-top: 2.8vw;
  margin-left: 25vw;
  padding-left:1vw ;
  padding-right:1vw ;
}
.eMed_Header_Search_Pop{
  margin-top: 2.8vw;
  margin-left: 13.5vw;
  padding-left:1vw ;
  padding-right:1vw ;
  height: 50%;
}
.eMed_Header_Filter_item{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-left:1vw ;
  padding-right:1vw ;
  margin-top: 1vw;
  height: 25vh;
  width: 22vw;
}

.eMed_header_Input{
    width: 23vw;
    height: 2.56vw;
    font-size: 0.8vw;
    background-color:white
}
.eMed_Pharmaheader_Input{
  width: 17vw;
  height: 2.56vw;
  font-size: 0.8vw;
  background-color:white
}

.eMed_WebCam_Btn_div_faceRecog{
  display: flex;
  width: 50%;
  justify-content: space-between;
  align-items: center;
  margin-left: 11vw;
  margin-top: 1.5vw;
}

.eMed_Header_Request_Pop{
  margin-top: 2.8vw;
  margin-right: 8vw;
  margin-left: 15vw;
  padding-left:1vw ;
  padding-right:1vw ;
  height: 50vh;
}
.eMed_Header_CreateRequest_Pop{
  /* margin-top: 10vw; */
  /* margin-right: -20vw; */
  /* margin-left: -9vw; */
  padding-left:1vw ;
  padding-right:1vw ;
  height: 50%;
}

.eMed_craeteappoint_Grid_row{
  height: 4vw;
  padding-left: 1.8vw;
}

.eMed_Header_Request_item{
  display: flex;
  flex-direction: row;
  width: 51vw;
  justify-content: space-between;
  align-items: center;
  padding-left:1vw ;
  padding-right:1vw ;
  height: 4vw;
}

.eMed_CreateRequest_BtnBox{
  display: flex;
  flex-direction: row;
  height: 3vw;
  justify-content: flex-end;
  align-items: center;
  padding-right: 1.5vw;
  margin-top: 0.5vw;
}

.eMed_Profile_details_div{
  border: 1px solid gray;
  padding: 0.5vw;
  margin: 0.5vw;
  border-radius: 0.5vw;
  box-shadow: 0px 1px 2px 0px gray;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}
.eMed_Profile_details_text{
  padding-left: 1vw;
}

.eMed_App_reqest_table_head{
  height: 2vw;
  background-color: rgb(226, 226, 226);
  display: flex;
  justify-content: space-between;
  align-items: center;
  width:52.5vw;
  padding-left: 1vw;
  padding-right: 1vw;
}

.eMed_pharmaHeader_Search_Pop{
  margin-top: 2.8vw;
  margin-left: 5.5vw;
  padding-left:1vw ;
  padding-right:1vw ;
  height: 50%;
}
.eMed_Request_sugg_Head{
  height: 2vw;
  background-color: rgb(226, 226, 226);
  display: flex;
  align-items: center;
  padding-left: 1vw;
  font-size: 0.8vw !important;
}

.eMEd_CreateRequest_Box{
  width:47.3vw;
}

.eMed_rad_header_search{
  margin-top: 2.8vw;
  margin-left: 3.5vw;
  padding-left:1vw ;
  padding-right:1vw ;
  height: 50%;
}

.eMed_lab_history_popup{
  position: absolute;
  top: 55%;
  left: 50.5%;
  transform: translate(-50%, -50%);
  width: 90vw;
  max-height: 90vw;
  height:78vh;
  background-color: white;
  border-radius: 8px;
  overflow: auto;
}