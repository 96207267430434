:root {
    --gray: #616161;
    --white: #FFFFFF;
    --fill: #E4F2F0;
}

#eMed_recPayment_Container {
    padding: 0.625vw;
}

.eMed_recPayment_Box {
    height: 80vh;
}

.eMed_recCard_header {
    height: 7.5vh;
}

.eMed_recCard_div {
    padding: 0.2vw;
    border-radius: 0.3vw;
    box-shadow: 0px 0px 3px var(--gray);
}

.eMed_recPayment_mainDiv {
    width: 94vw;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-right: 0.8vw;
}

.eMed_IP_recPayment_Div {
    height: 58vh;
    /* margin-top: -0.95vw; */
}

.eMed_recPayment_rightHead {
    height: 5vh;
    border-top-left-radius: 0.3vw;
    border-top-right-radius: 0.3vw;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 0.5vw;
}

.eMed_recPayment_rightHead_txt {
    font-weight: 600;
    margin-left: 1.3vw;
    color: #616161;
}

.eMed_recPayment_subDiv {
    width: 100%;
    height: 54vh;
    overflow-y: scroll;
}

.eMed_refundPayment_subDiv {
    width: 100%;
    height: 15vw;
    overflow-y: scroll;
}

.emedhub_recPayment_RightContentBox {
    background-color: var(--white);
    padding: 0.625vw;
    margin-left: 0.4vw;
}

#eMed_recPayment_rightBtnCon {
    height: 5vh;
    padding: "0.625vw";
    border-bottom-left-radius: 0.3vw;
    border-bottom-right-radius: 0.3vw;
}

#eMed_recPayment_btn {
    text-transform: capitalize;
}

#eMed_recPayment_Txt {
    font-size: 1vw;
    font-weight: 600;
}

#eMed_recPayment_divs {
    margin: 0.5;
}

#eMed_recPayment_radioTxt {
    font-size: 0.78vw;
    color: var(--gray);
}

#eMed_recPayment_checkTxt {
    font-size: 0.88vw;
    color: var(--gray);
}

#eMed_recPayment_radioCorpTxt {
    font-size: 0.7vw;
    color: var(--gray);
}

.eMed_recPayment_Denomi_div {
    background-color: var(--fill);
    width: 27.5vw;
    height: 12vw;
    border-radius: 0.3vw;
}

.eMed_recPayment_Denomi_invidual_div {
    margin-left: 1.2vw;
    margin-top: 0.8vw;
    width: 10vw;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

#eMed_recPayment_advTxt {
    font-weight: 600;
}

.eMed_patient_container {
    background-color: #F5F8F7;
    height: 80vh;
}

.eMed_bill_content {
    height: 68vh;
    display: flex;
}

.eMed_bill_btn_one {
    margin: 0.5vw 0.5vw !important;
    text-transform: capitalize !important;
}

.eMed_bill_tbl_footer_IP {
    display: flex;
    justify-content: space-between;
    height: 1.77vw;
    width: 22vw;
    margin-right: 1vw;
}

.eMed_header_img {
    margin-left: 0.5vw;
    height: 1vw;
    width: 1vw;
}

.eMed_delete_img {
    height: 1.2vw;
    width: 1.2vw;
}

.eMed_right_grid_one {
    height: 21vw;
    margin-bottom: 1.5vw !important;
    background-color: white;
}

.eMed_recPayment_gridDiv{
    height: 61.3vh;
    background-color: white;
}

.eMed_tab_header {
    font-weight: 600 !important;
    text-transform: capitalize !important;
}

.eMed_bill_search {
    width: 25vw;
}

.eMed_chip {
    margin: 0.3vw 0.3vw !important;
}

.eMed_right_sub_grid {
    height: 5vw;
    margin-top: 0.5vw;
    display: flex;
}

.eMed_date_picker_grid {
    background-color: #F5F8F7;
    height: 5vw;
    margin-top: 0.5vw;
    margin-left: 0.5vw;
    width: 16vw;
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;
}

.eMed_date_picker_grid_amount {
    background-color: #F5F8F7;
    height: 5vw;
    margin-top: 0.5vw;
    margin-left: 0.5vw;
    width: 12vw;
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;
}

.eMed_bill_right_card {
    width: 30vw;
    height: 64vh;
    margin: auto;
    background-color: white;
    box-shadow: 0px 0px 1px gray;
}

.eMed_bill_select {
    width: 16.5vw;
    margin: 1vw 0.5vw 1vw 0.5vw !important;
}

.eMed_bill_select_two {
    width: 8vw;
    margin: 1vw 0.5vw 1vw 0.5vw !important;
}

.eMed_bill_radio {
    display: flex;
    flex-direction: row !important;
    margin-left: 0.5vw;
}

.eMed_discount_txt {
    margin-right: 0.5vw !important;
    width: 7vw;
}

.eMed_Bill_add_btn {
    height: 1.5vw;
    min-width: 0vw !important;
    width: 1.5vw;
    position: relative;
    right: 0.5vw;
}

.eMed_edit_btn_bill {
    height: 1vw;
    min-width: 0vw !important;
    width: 1vw;
    margin-top: 0.3vw !important;
}

.eMed_bill_table {
    height: 40.5vh !important;
}

.eMed_search_icon {
    width: 2.5vw !important;
    position: relative;
    right: 2.4vw;
    height: 2.5vw !important;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    background-color: #04B7B1;
    color: white;
    padding: 0.4vw;
    cursor: pointer;
}

.eMed_bill_header_one {
    margin-left: 0.8vw !important;
}

.eMed_patient_header {
    height: 9.5vh;
}

.eMed_bill_btn_one {
    margin: 0.5vw 0.5vw !important;
    text-transform: capitalize !important;
}

.eMed_bill_table_card {
    height: 64vh;
    background-color: white;
    width: 61vw;
    margin: auto;
    box-shadow: 0px 0px 1px gray;
}

.eMed_bill_tbl_footer {
    display: flex;
    justify-content: space-between;
    height: 2vw;
    width: 22vw;
    margin-right: 1vw;
}

.eMed_header_img {
    margin-left: 0.5vw;
    height: 1vw;
    width: 1vw;
}

.eMed_Time_img {
    height: 1.2vw;
    width: 1.7vw;
    padding-right: 0.5vw;
    margin-top: 0.1vw;
}

.eMed_Tick_img {
    height: 1.7vw;
    width: 1.7vw;
    margin-top: 0.05vw;
    padding-right: 0.5vw;
}

.emedhub_DatePicker_container{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 0.5vw;
    margin-top: 1vw;
    margin-bottom: 0.5vw;
}

.eMed_Time_field{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.eMed_delete_img {
    height: 1.2vw;
    width: 1.2vw;
}

.eMed_right_grid_one {
    height: 21vw;
    margin-bottom: 1.5vw !important;
    background-color: white;
}

.eMed_tab_header {
    font-weight: 600 !important;
    text-transform: capitalize !important;
}

.eMed_bill_search {
    width: 20vw;
}

.eMed_bill_list {
    overflow: scroll;
    height: 14vw;
}


.eMed_bill_select {
    width: 16.5vw;
    margin: 1vw 0.5vw 1vw 0.5vw !important;
}

.eMed_bill_select_two {
    width: 8vw;
    margin: 1vw 0.5vw 1vw 0.5vw !important;
}

.eMed_bill_radio {
    display: flex;
    flex-direction: row !important;
    margin-left: 0.5vw;
}

.eMed_discount_txt {
    margin-right: 0.5vw !important;
    width: 7vw;
}

.eMed_add_btn {
    height: 1.5vw;
    min-width: 0vw !important;
    width: 1.5vw;
    position: relative;
    right: 0.5vw;
}

.eMed_edit_btn_bill {
    height: 1vw;
    min-width: 0vw !important;
    width: 1vw;
    margin-top: 0.3vw !important;
}

/* .eMed_search_icon {
    width: 2.5vw !important;
    position: relative;
    right: 2.4vw;
    height: 2.5vw !important;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    background-color: #04B7B1;
    color: white;
    padding: 0.4vw;
    cursor: pointer;
} */

.eMed_bill_header_one {
    margin-left: 0.8vw !important;
}

.eMed_patient_header {
    height: 7vh;
}

.eMed_patient_content {
    display: flex;
    margin: auto;
}

.eMed_patient_grid {
    height: 29vw;
    width: 22.8vw;
    padding: 0.2vw;
    margin-top: 0.5vw;
    background-color: white;
    border-radius: 5px;
    box-shadow: 0px 0px 3px gray;
    margin: 1vw 0.2vw 0.5vw 0.25vw;
}

.eMed_patient_table_header {
    padding: 0.5vw;
    height: 3vw;
    display: flex;
}

.eMed_patient_text {
    flex: 0.8;
    font-weight: 600 !important;
    color: #616161;
}

.eMed_patient_img {
    flex: 0.2;
    height: 2.2vw;
    display: flex;
    justify-content: flex-end;
}

.eMed_patient_img_res {
    width: 2vw;
    height: 2vw;
}

.eMed_patient_datagrid {
    background-color: white;
    height: 25dvw !important;
    width: 21.5dvw;
    margin: auto;
}

.eMed_patient_admin {
    border: 1px solid lightgray;
    border-radius: 5px;
    height: 25vw;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    overflow: scroll;
}

.eMed_patient_card {
    height: 70vh;
    overflow: scroll;
    margin-top: 0.75vw;
}

.eMed_patient_box {
    margin-left: 0.3vw;
}

.eMed_table_footer {
    display: flex;
    border-top: 1px solid lightgray;
}

.eMed_table_footer {
    display: flex;
}

.eMed_bill_footer_right {
    display: flex;
    flex-direction: column;
    flex: 0.5;
    justify-content: space-between;
}

.eMed_bill_footer_left {
    flex: 0.5;
    position: relative;
    left: 1vw;
}

.eMed_bill_container {
    background-color: #F5F8F7;
    height: 80vh;
}

.eMed_service_name {
    overflow: scroll;
}

.eMed_service_type {
    height: 7vw;
}

.eMed_Service_title {
    margin-left: 0.5vw !important;
    font-size: 0.8vw !important;
    font-weight: 600 !important;
    color: #616161;
}

.eMed_room_container {
    height: 80vh;
    background-color: #F5F8F7;
}

.eMed_room_header {
    height: 9.5vh;
}

.eMed_room_content {
    margin-top: -0.6vw;
    height: 68vh;
    display: flex;
}

.eMed_room_left_content {
    flex: 0.65;
}

.eMed_room_right_content {
    flex: 0.35;
}

.eMed_room_left_card {
    width: 59.5vw;
    margin: 0.3vw auto;
    height: 67.5vh;
}

.eMed_room_right_card {
    margin: 0.3vw auto;
    width: 31.5vw;
    height: 67.5vh;
    box-shadow: 0px 0px 1px gray;
}

.eMed_room_grid {
    background-color: white;
}

.eMed_room_right_header {
    height: 5vh;
    display: flex;
    align-items: center;
    padding-left: 1vw;
}
.eMed_room_right_content {
  height: 56vh;
}

.eMed_room_right_top {
    height:24.15vh;
    background-color: white;
}

.eMed_room_right_bottom {
    background-color: white;
    height: 28.8vh;
    margin-top: 2vw;
}

.eMed_room_right_footer {
    background-color: #F5F8F7;
    height: 5.5vh;
    border-bottom-left-radius: 0.3vw;
    border-bottom-right-radius: 0.3vw;
}

.eMed_room_select_one {
    width: 14vw;
    margin: 1vw 0.5vw 0.5vw 1vw !important;
}

.eMed_room_select_two {
    width: 6.5vw;
    margin: 1vw 0vw 0.5vw 1vw !important;
}

.eMed_room_radio {
    margin-left: 1vw !important;
}

.eMed_room_box_one {
    width: 14vw;
    margin: 1vw 0.5vw 0.5vw 1vw !important;
    height: 4.5vw;
}

.eMed_room_date_field {
    margin-top: 0.5vw !important;
    padding: 0.5vw !important;
}

.eMed_room_text_one {
    width: 6vw;
    margin-top: 1vw !important;
    margin-left: 0.5vw !important;
}

.eMed_room_text {
    width: 7vw;
    height: 4.5vw;
}

.eMed_room_change_modal {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 30vw;
    height: 17vw;
    background-color: white;
    border-radius: 10px;
}

.eMed_room_model_header {
    margin: 1vw;
    background-color: #F5F8F7;
}

.eMed_room_modal_btn {
    margin: 1vw;
    display: flex;
    justify-content: space-between;
}

#eMed_medication_Container {
    height: 80vh;
    padding: 0.625vw;
}

.eMed_medication_Box {
    height: 70vh;
}

.eMed_medication_mainDiv {
    height: 65vh;
}

.eMed_medication_Div {
    width: 100%;
    height: 64vh;
    background-color: var(--white);
}

.eMed_medication_TblDiv{
    width: 100%;
    height: 63vh; 
}

.eMed_medication_subDiv {
    width: 100%;
    height: 51.7vh;
    overflow-y: scroll;
}

.eMed_lab_subDiv {
    width: 100%;
    height: 58.7vh;
}

.eMed_medication_rightHead {
    height: 6.5vh;
    border-top-left-radius: 0.3vw;
    border-top-right-radius: 0.3vw;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 0.5vw;
}

.emedhub_medication_RightContentBox {
    background-color: var(--white);
    padding: 0.5vw;
    margin: 0 auto;
}

#eMed_medication_chipDiv {
    width: 27.5vw;
    /* min-height: 20vh;
    max-height: 30vh; */
    margin: 0.1vw auto;
}

#eMed_medication_chip {
    border-radius: 0.3vw;
    margin: 0.3vw 0.5vw 0.3vw 0vw;
    height: 2.3vw;
}

.emedhub_medication_img {
    width: 1.5vw;
    height: 1.5vw;
}
.emedhub_medications_img {
    width: 1.5vw;
    height: 1.5vw;
    margin-top: 0.3vw;
}

.eMed_medication_maenDiv {
    width: 27.5vw;
    height: 23vh;
    margin: 0.5vw;
    padding: 0.2vw;
}

#eMed_medication_maenChip {
    border-radius: 0.3vw;
    width: 2.4vw;
    height: 2.4vw;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 0.75vw;
    font-weight: 600;
    cursor: pointer;
    border-width: 1px;
    border-style: solid;
}

#eMed_medication_maenChipTxt {
    width: fit-content;
    margin-right: 0.4vw;
    font-size: 0.78vw;
    font-weight: 600;
    color: var(--gray);
}

.eMed_medication_btnDiv {
    margin-top: 0.5vw;
}

#eMed_medication_btn {
    width: 13.90vw;
    text-transform: capitalize;
}

.eMed_medication_instruImg {
    width: 3.8vw;
    height: 3.8vw;
}

#eMed_medication_instruDiv {
    width: 27.5vw;
    height: 8vh;
    padding: 0 auto;
}

.eMed_medication_txtbox {
    display: flex;
    margin: 0.3vw;
}

.eMed_medication_durChipDiv {
    width: 27.5vw;
    height: 13vw;
    padding: 0.2vw;
}

#eMed_medication_durChip {
    border-radius: 0.3vw;
    width: 2.5vw;
    height: 2.5vw;
    margin: 0.25vw;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 0.7vw;
    font-weight: 600;
    cursor: pointer;
    border-width: 1px;
    border-style: solid;
}

.eMed_medication_tapperDiv {
    width: 28vw;
    height: fit-content;
    margin: 0.625vw auto;
}

.eMed_medication_tapperSubDiv {
    width: 27.5vw;
}

.eMed_Discharge_PaperBillView{
    width: 100%;
    height: 69vh;
    padding: 0.625vw;
}
.eMed_Discharge_txtbox{
    width: 42vw;
}

.eMed_nur_note_container {
    height: 74vh;
    background-color: #F5F8F7;
}

.eMed_nur_note_header {
    height: 11vh;
}

.eMed_nur_note_card {
    height: 62vh;
    margin-top: 0.3vw;
    display: flex;
    margin-left: 0.3vw;
}

.eMed_nur_note_left {
    flex: 0.6;
    height: 64vh;
}

.eMed_nur_note_right {
    flex: 0.4;
    height: 64vh;
}

.eMed_nur_notes_right_card {
    width: 36.5dvw;
    height: 64.5dvh;
    margin: 0.2dvw auto;
    background-color: white;
    box-shadow: 0px 0px 1px gray;
}

.eMed_nur_notes_left_card {
    height: 64.5dvh;
    width: 55dvw;
    margin: 0.2dvw auto;
}

.eMed_nur_notes_grid {
    background-color: white;
}

.eMed_nur_notes_title {
    background-color: #F5F8F7;
    height: 5vh;
    padding: 0.4vw;
}

.eMed_nur_notes_content {
    height: 52.8vh;
    margin-left: 0.5vw;
    overflow: auto;
}

.eMed_nur_notes_btn_card {
    background-color: #F5F8F7;
    height: 5.5vh;
}

.eMed_nur_notes_btn {
    display: flex;
    justify-content: center;
    align-items: center;
}

.eMed_nur_note_header {
    display: flex;
}

.eMed_Discharge_PaperView {
    width: 92vw;
    height: 69vh;
    background-color: white;
    margin: 1.5vw 0.5vw 0vw 0.8vw;
}

.eMed_IP_Discharge_Header {
    background-color: #FAF9F9;
    height: 7vh;
    border-radius: 4px 4px 0px 0px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.eMed_IP_Discharge_Summary_Select {
    width: 73vw;
    padding: 0.3vw;
    display: flex;
    justify-content: space-between;
}

.eMed_room_model_content {
    margin: 1vw;
    display: flex;
    justify-content: center;
}

.eMed_IP_Discharge_BtnGroup {
    display: flex;
    justify-content: center;
}

.eMed_IP_Discharge_Select {
    width: 13vw;
}

.eMed_IP_Discharge_Select {
    width: 13vw;
}

.eMed_vital_Container {
    height: 65dvh;
    margin-top: 0.5dvw;
}

.eMed_vital_left {
    height: 65dvh;
}

.eMed_vital_table {
    height: 64dvh;
    width: 100%;
    background-color: white;
}

.eMed_vital_table1 {
    height: 62.5dvh;
    width: 100%;
    background-color: white;
}

.eMed_vital_right {
    height: 65dvh;

}

.eMed_vital_right_card {
    height: 64vh;
    width: 100%;
}

.eMed_vital_right_card1 {
    height: 62.5dvh;
    width: 100%;
}

.eMed_vital_right_txtbox {
    height: 52.5vh;
    overflow: scroll;
}

.eMed_vital_right_txtbox1 {
    height: 50.3vh;
    overflow: scroll;
}

.eMed_vital_more {
    min-width: 0vw !important;
    position: relative;
    color: #616161 !important;
    width: 0.5vw;
}

.eMed_SN_Con {
    width: 92.5vw;
    height: 70vh;
    overflow: scroll;
    margin: 1.5vw 0.5vw 0vw 0.8vw;
}
.eMed_SN_IpCon{
    width: 100%;
    height: 70vh;
    overflow: scroll;
    margin: 1.5vw 0.5vw 0vw 0vw;
}

.eMed_SN_Acc_Header {
    flex-direction: row-reverse;
    display: flex;
}

.eMed_SN_Acc_HeadBox {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 89vw
}

.eMed_SN_Acc_HeadText {
    display: flex;
    align-items: center
}

.eMed_SN_Acc_HeadLay {
    width: 89vw;
    display: flex;
    justify-content: flex-end;
    margin-bottom: 1vw;
}

.eMed_SN_NoLayout {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 41vh;
}

.eMed_SN_Sign_View {
    margin: 2vw 1vw 1vw 1vw;
    width: 87vw;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.eMed_SN_Add_Btn {
    margin-top: 1vw;
    display: flex;
    justify-content: flex-end;
    margin-right: 0.5vw;
}

.eMed_nur_notes_details {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    background-color: white;
    margin: 0.5vw 0.5vw 0vw 0.8vw;
    border-radius: 5px;
    box-shadow: 0px 0px 3px gray;
    width: 23vw;
    padding: 0.2vw;
}

.eMed_patient_nur_notes {
    margin-left: 0.75vw;
}

.eMed_history_nurse_grid {
    height: 22vw;
    width: 50vw;
}

.eMed_vital_grid_history {
    height: 22vw;
    width: 70vw;
}

.eMed_table_empty {
    position: relative;
    top: 8vw;
}

.eMed_DisSum_Anaesthsia {
    width: 90vw;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-left: 1vw;
}

.eMed_text_room_change{
    display: flex;
    flex-direction: column;
    padding: 1vw;
}

.eMed_text_box_room{
    margin: 1vw;
}

.eMed_room_canceled{
    background-color: gray;
}

.eMed_medication_head{
    width: 100%;
    display: flex;
    flex: 1;
}
#eMed_medication_headTxt {
    font-weight: 600;
    font-size: 0.9vw;
    color: #616161;
}
#eMed_medication_rtnTbl{
    max-height: 40vh;
}
.eMed_medication_Img{
    width: 2vw;
    height: 2vw;
}
.eMed_Medication_arrowImg{
    width: 0.9vw;
    height: 1vw;
}
#eMed_Medication_tableTxt{
    font-weight: 600;
    color: #616161;
    font-size: 0.9vw;
    background-color: #F5F8F7 !important;
    padding: 0.5vw !important;
}
.eMed_Medication_tableDiv{
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0px 0px 1px lightgray;
    border: 1px solid lightgray;
}
.eMed_medicationCard_header{
    height: 6vh;
    margin-bottom: 0.8vw;
}

#eMed_Serivce_Search{
    cursor: pointer; 
    border-left: 1px solid #E3E2E2;
    width: 3vw; 
    height: 2vw;
    display: flex;
    justify-content: center;
    margin-right: -0.9vw
}

.eMed_op_Bill_list{
    height: 14vw;
    overflow: scroll;
}
.eMed_pain_left_card {
    /* width: 60.5dvw; */
    width: 100%;
    margin: 0.3dvw auto;
    height: 64dvh;
    margin-top: 0.5dvw;
}
.eMed_pain_right_card {
    height: 64dvh;
    /* width: 30dvw; */
    width: 100%;
    /* margin-left: 1.5dvw; */
    margin-top: 0.5dvw;

}
.eMed_pain_rgt_title{
    background-color: #F5F8F7;
    width: 30vw;
    height: 2.5vw;
    padding: 0.5vw;
}
.emedhub_auto_complate {
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    margin: 0.625dvw 0dvw;
}
.emedhub_pain_level {
  width: 13dvw;
}
.emedhub_Nurse_list {
  width: 13dvw;
  margin-left: 0.5dvw;
}
.emedhub_pain_button {
    background-color: var(--white);
    padding: 0.5vw;
    margin: 0 auto;
}
.emedhub_pain_sub_container {
    height: 51vh;
}
.emedhub_toolbar_button {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-left: -0.7vw;
}
.emedhub_pain_chart_main {
    height: 30vh;
    width: 20vw;
}
.emedhub_pain_range {
  height: 1.2vw;
  width: 1.2vw;
}


.eMed_insulin_left_card{
    flex:0.65;
    height: 65vh;
    overflow-y: scroll;
}

.eMed_insulin_right_card {
    flex: 0.35;
    height: 65vh;
    overflow-y: scroll;
}

.eMed_bill_Tab{
    min-height: 0vw !important;
    height: 2.5vw !important;
}

.eMed_DisSum_Copy_popMain {
    position: absolute;
    top: 42%;
    left: 42%;
    transform: translate(-40%, -40%);
    width: 70vw;
    height: 83vh;
    background-color: white;
    border-radius: 5px;
    overflow: auto;
}

.eMed_DisSum_Copy_head_Box {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    position: sticky;
    top: 0;
    z-index: 1000;
    background-color: white;
}

.eMed__DisSum_Copy_BtnGr {
    margin: 1vw;
    display: flex;
    align-items: center;
}

.eMed_drugRight_txtDiv {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
}

.eMed_drugMenu {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.eMed_drugMenu_item {
    width: 1dvw;
    height: 1dvw;
    margin-right: 0.5dvw;
}

.eMed_ipop_qtyDiv {
    display: grid;
    grid-template-columns: 60% 40%;
}

.eMed_ipop_right_txt {
    font-weight: 600;
    color: #616161;
    margin: 0dvw 0.5dvw !important;
    font-size: 0.9dvw;
}

.eMed_nutri_txtDiv {
    width: 100%;
    padding: 0.5dvw !important;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 1dvw;
}

.eMed_drug_status_start {
    background-color: #E3FFE6;
}

.eMed_drug_status_stop {
    background-color: #FFE3E3;
}

.eMed_drug_status_hold {
    background-color: #FFF8BC;
}

.eMed_drug_status_Completed{
    background-color: #FFFFFF;
}

.eMed_ipop_txtDiv {
   margin: 0dvw 0.5dvw 0.3dvw 0.5dvw !important;
}