.eMed_refdoc_content {
    height: 80vh;
}

.eMed_refdoc_left {
    width: 60vw;
    height: 73vh;
    margin-top: 1vw;
    margin-left: 1vw;
    background-color: white;
}

.eMed_refdoc_right {
    height: 73vh;
    background-color: white;
    width: 30vw;
    margin-top: 1vw;
    margin-right: 1vw;
}

.eMed_common_Tbl_left {
    width: 57vw;
    height: 76vh;
    margin-top: 1vw;
    margin-left: 1vw;
    background-color: white;
}

.eMed_common_Tbl_right {
    height: 76vh;
    background-color: white;
    width: 34vw;
    margin-top: 1vw;
    margin-left: 0.5vw;
}

.eMed_refdoc_txt_card {
    margin-top: 1vw;
    height: 30.5vh;
    padding: 1vw 1vw 1vw 1vw;
}

.eMed_refdoc_btn_card {
    position: relative;
    top: 13.3vw;
    height: 7vh;
    background-color: #FAF9F9;
}


.eMed_refdoc_add_btn {
    width: 6vw;
    height: 4.5vh;
    position: relative;
    top: 0.5vw;
}

.eMed_refsrc_txt_card {
    height: 61vh;
    padding: 1vw;
}

.eMed_refsrc_txt {
    height: 8vh;
}

.eMed_refdoc_grid {
    display: flex;
    justify-content: end;
    height: 7vh;
}

#eMed_Config_mainDiv {
    /* width: 100%;
    height: 100%;
    display: flex;
    flex-wrap: wrap;
    padding: 1vw;
    padding-top: 0; */
}

.eMed_Config_Wrapper {
    margin-top: 0.5vw;
    padding-top: 0.3vw;
    border-radius: 0.3vw;
    width: 100%;
    height: 97%;
    overflow-y: auto;
}

#eMed_Config_header {
    padding: 0.5vw;
    margin-top: 0.5vw;
    /* padding-left: 1.5vw; */
    font-weight: 600;
}

.eMed_Config_Div {
    margin-top: 0.5vw;
}

.eMed_srvConfig_Box {
    height: 80vh;
}

#eMed_srvConfig_Container {
    margin-top: 1.5vw;
    padding: 0 0.625vw 0 0.625vw;
}

.eMed_srvConfig_Div {
    height: 75vh;
}

.eMed_srvConfig_subDiv {
    /* padding: 1vw; */
    height: 65vh;
    overflow: scroll;
  
}
.eMed_Colour_Container{
    display: flex;
    flex-direction: row;

}

.eMed_Colour_text{  
    margin-left: 1vw;
    margin-top: -48vh;
}

.eMed_Colorchoser{
    display: flex;
    flex-direction: row;
    padding: 1px;
    border: 1px solid rgb(12, 12, 12);
    border-radius: 50%;
    height: 4.0vh;
    width: 2vw;
    margin-top: 2vh;
    margin-left: 1.5vw;  
}
.favouriteColor{
    display: flex;
    flex-direction: row;
    border: 1px solid rgb(12, 12, 12);
    height: 4.5vh;
    width: 2.5vw;
    margin-top: 2vh;
    margin-left: 1.5vw;
    border: none;
   
}
 
.emed_ColorShow{
    width: 5vw; 
    border:2px solid black;
    border-radius: 5px;
    margin-top: 1vh;
    margin-left: 10px ;
    height: 5vh;
}
.emed_ColorShowgrid{
    width: 1vw; 
    border:1px solid black;
    border-radius: 3px;
    margin-top: 1vh;
    margin-left: 10px ;
    height: 1vh;
    background-color: rgb(36, 21, 248);

}

.eMed_arrowBtn {
    width: 0.8vw;
    height: 0.8vw;
}

#eMed_srvConfig_divs {
    margin: 0.5vw;
}

#eMed_srvConfig_rightTbl {
    width: 29.8vw;
    height: 26vh;
    margin: 0.5vw;
}

#eMed_srvConfig_accTbl {
    max-height: 30vh;
}

.eMed_srvConfig_rightHead {
    height: 5vh;
    border-top-left-radius: 0.3vw;
    border-top-right-radius: 0vw;
    border-bottom-left-radius: 0.3vw;
    border-bottom-right-radius: 0vw;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.eMed_srvConfig_rightHead_txt {
    font-weight: 600;
    margin-left: 1.5vw;
    color: #616161;
}

#eMed_Config_btn {
    text-transform: capitalize;
}

#eMed_srvConfig_rightBtnCon {
    height: 5vh;
    padding: "0.625vw";
    border-top-left-radius: 0vw;
    border-top-right-radius: 0vw;
    border-bottom-left-radius: 0.3vw;
    border-bottom-right-radius: 0.3vw;
}

#eMed_srvConfig_rightTbl2 {
    width: 29.8vw;
    height: 53vh;
    margin: 0.8vw 0.5vw;
}

#eMed_activeBtn {
    width: 1.6vw;
    height: 1.6vw;
}

.eMed_usrconf_container {
    height: 80vh;
    background-color: #F5F8F7;
}

.eMed_usrconf_header {
    height: 8vh;
    display: flex;
    justify-content: end;
    align-items: center;
}

.eMed_usrconf_btn {
    min-width: 0vw !important;
    height: 2vw;
}

.eMed_usrconf_img {
    height: 2vw;
    width: 2vw;
}

.eMed_refdoc_txtbox {
    width: 13vw;
}

.eMed_refdoc_add {
    padding-left: 1.2vw;
}

.eMed_conf_nodata {
    height: 50vh;
    display: flex;
    align-items: center;
    justify-content: center;
}

.eMed_refdoc_container {
    margin-top: 1.05vw;
    background-color: #F5F8F7;
}

.eMed_common_container {
    margin-top: 0.2vw;
    background-color: #F5F8F7;
}

.eMed_refdoc_title {
    height: 6vh;
    display: flex;
    align-items: center;
    padding-left: 1vw;
    background-color: #F5F8F7;
    font-weight: 600 !important;
    color: #616161;
}

.eMed_docnotes_sub_title {
    height: 4vh;
    display: flex;
    align-items: center;
    padding-left: 1vw;
    font-weight: 600 !important;
    color: #616161;
    font-size: 0.9vw !important;
}


.eMed_refdoc_clr_btn {
    padding-left: 8vw;
}


.eMed_corpconfig_txt_card {
    height: 61.5vh;
    padding: 1vw 1vw 1vw 1vw;
}

.eMed_roomconfig_txt_card {
    height: 62vh;
    padding: 1vw 1vw;
}

.eMed_Right_contents {
    height: 63.5vh;
    padding: 1vw 1vw;
    overflow-y: scroll;
}

.eMed_corpConfig_btn_card {
    position: relative;
    top: 1vw;
    height: 7vh;
    background-color: #FAF9F9;
}

.eMed_Patient_Country {
    width: 13.5vw;
    height: 2.6vw;
    border-color: #bbb9b9;
    color: #888888;
    border-radius: 0.3vw;
    margin-top: 0.8vw;
}

.eMed_ref_btn {
    height: 6.8vh;
    padding: 0.625vw;
}

.eMed_refsrc_btn {
    height: 6vh;
    padding: 0.625vw;
}

.eMed_demolayout_right1 {
    height: 13vh;
    background-color: white;
    width: 30vw;
    margin-top: 1vw;
    margin-right: 1vw;
}

.eMed_demolayout_right2 {
    height: 54.8vh;
    background-color: white;
    width: 30vw;
    margin-top: 0.2vw;
    margin-right: 1vw;
    overflow-y: scroll;
}

.eMed_demolayout_right3 {
    height: 7vh;
    background-color: white;
    width: 30vw;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.eMed_demolayout_left1 {
    width: 60vw;
    height: 23vh;
    margin-top: 1vw;
    margin-left: 1vw;
    background-color: white;
    /* overflow-y: scroll; */
}

.emed_demo_left_title_right {
    height: 6vh;
    display: flex;
    align-items: center;
    padding-right: 1vw;
    /* background-color: #FAF9F9; */
    font-weight: 500 !important;
    color: #888888;
}

.eMed_demolayout_left2 {
    width: 60vw;
    height: 50vh;
    margin-top: 0.2vw;
    margin-left: 1vw;
    background-color: white;
    /* overflow-y: scroll; */
}

.emed_demolayout_right1_box {
    padding-top: 1vh;
    flex-direction: row;
    display: flex;
}

.emed_demolayout_right1_select {
    margin-left: 0.5vw;
    margin-right: 0.5vw;
}

.eMed_demolayout_right2_field {
    display: flex;
    flex-direction: row;
    padding: 0.2vw;
    margin-left: 1vw;
    border-bottom: 1px solid #E3E2E2;
}

.eMed_demolayout_right2_field_img {
    margin-right: 1vw;
}

.eMed_DisSum_Lay_DragImg {
    margin-right: 1vw;
    width: 1vw;
    height: 1vw;
}

.eMed_demolayout_left_TextInput {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    padding-left: 1vw;
    padding-bottom: 1vh;
    /* height: 20vw;
    overflow-y: scroll; */
}

.eMed_demolayout_left_TextInput_field {
    margin-top: 2.5vh;
    margin-left: 1vw;
    width: 12vw;
}

#eMed_srvTemp_rightTbl {
    width: 29.8vw;
    height: 31.8vh;
    margin: 0.5vw;
}

.eMed_conf_user_table {
    height: 70vh;
}

.eMed_usrConf_tbl {
    background-color: white;
    width: 92.6vw;
    margin: auto;
}

.eMed_DisSum_Lay_Select {
    height: 6vh;
    display: flex;
    align-items: center;
    justify-content: space-between;
    /* justify-content: flex-end; */
    /* padding-right: 1vw; */
    background-color: #FAF9F9;
}

.eMed_DisSum_Lay_Spec {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
}

.eMed_DisSum_Lay_select2 {
    width: 10vw;
}

.eMed_DisSum_TxtBox {
    width: 17vw;
}

.eMed_DisSum_right_Btn {
    border-radius: 0px 0px 3px 3px;
}

.eMed_Dissum_lay_Con {
    margin: 0.8vw;
    height: 57.8vh;
}

.eMed_demolayout_right2_field1 {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0.2vw;
    margin-left: 1vw;
    border-bottom: 1px solid #E3E2E2;
}

.eMed_DisSum_Lay_StarIcon {
    width: 1vw;
    height: 1vw;
}

.eMed_DisSum_Lay_LeftHead {
    display: flex;
    flex-direction: row;
    padding: 0.5vw;
}

.eMed_DisSum_Lay_right_Head {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 1vw;
}

#eMed_DisSum_Lay_right_HeadTxt {
    font-weight: 600;
}

#eMed_DisSum_Lay_right_HeadTxt2 {
    font-size: 0.7vw;
    color: #616161;
}

.eMed_DisSum_Lay_right_Feilds {
    height: 46vh;
    overflow: scroll;
}

.eMed_DisSum_Lay_StarBtn {
    margin-right: 1vw;
}

.eMed_DisSum_Lay_AdmDig {
    display: flex;
    flex-direction: row;
    width: 43vw;
    justify-content: space-between;
}

.eMed_DisSum_Icd_TxtBox {
    width: 26vw;
}

.eMed_DisSum_Lay_TblDel {
    width: 1.3vw;
    height: 1.3vw;
}

.eMed_DisSum_Lay_CommonBox {
    margin: 1vw;
}

.eMed_DisSum_Lay_CommonBox1 {
    margin: 2vw 1vw 1vw 1vw;
}

.eMed_DisSum_com_TxtBox {
    width: 57.6vw;
    height: 12vh;
}

.eMed_DisSum_Lay_disBox {
    margin: 2vw 1vw 1vw 1vw;
    border: 1px solid #E3E2E2;
    height: 29vh;
    border-radius: 0.3vw;
}

.eMed_DisSum_Lay_Diet {
    width: 27vw;
}

.eMed_DisSum_AddIcon {
    display: flex;
    flex-direction: row;
    margin: 1vw;
    justify-content: space-between;
}

.eMed_DisSum_Lay_CommonBox2 {
    margin: 1vw 1vw 2vw 1vw;
    height: 20vh;
}

.eMed_DisSum_Lay_leftCon {
    height: 65vh;
    overflow: scroll;
}

.eMed_DisSum_Lay_AddField {
    display: flex;
    flex-direction: row;
    width: 30vw;
    justify-content: space-between;
}

.eMed_DisSum_Lay_Anaesthsia {
    width: 57vw;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 0.5vw;
}

.eMed_DisSum_Lay_Dietcon {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 0.5vw;
}

.eMed_DisSum_Lay_Advice {
    width: 55.5vw;
    margin-top: 1vw;
}

#eMed_ipsrvConfig_accTbl {
    width: 100%;
    height: 47dvh;
}

.eMed_ipPackage_Div {
    height: 63vh;
}

.eMed_ipPackage_subDiv {
    padding: 1vw;
    margin-right: 0.5vw;
}

#eMed_ipPackage_rightTbl {
    height: 36vh;
    margin: -1.8vw 0.5vw 0.5vw 0.5vw;
}

#eMed_ipPackage_rightBtnCon {
    height: 9.8vh;
    padding: "0.625vw";
    border-top-left-radius: 0vw;
    border-top-right-radius: 0vw;
    border-bottom-left-radius: 0.3vw;
    border-bottom-right-radius: 0.3vw;
}

#eMed_ipPackage_txt1 {
    font-size: 0.6vw;
    font-weight: 600;
}

#eMed_ipPackage_txt2 {
    font-size: 0.8vw;
    font-weight: 600;
}

.eMed_WardConfig_Table {
    padding: 1vw;
    /* border: #888888 1px solid; */
    height: 15vw;
    margin-top: 0.3vw !important;
    border-radius: 0.3vw;
}

.eMed_Row_Div {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-left: 1vw;
    margin-right: 1vw;
}

.eMed_small_textFields {
    width: 15vw;
    margin-top: 1vw !important;
}

.eMed_small_textFields2 {
    width: 13vw;
    margin-top: 1vw !important;
}

.eMed_small_textFields3 {
    width: 14.5vw;
    margin-top: 1vw !important;
}

.eMed_Right_Fields_Div {
    height: 70vh;
}

.eMed_SplitUp_TextFields_div {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0.5vw;
}

.eMed_Accord_Div {
    height: 10vw;
    overflow-y: scroll;
}

.eMed_Room_Accord {
    min-height: 20vw;
    overflow-y: scroll;
    margin-top: 0.3vw
}

.eMed_Dis_Template_MainCon {
    width: 92vw;
    height: 76vh;
    background-color: white;
    border: 1px solid #E3E2E2;
    border-radius: 0.3vw;
    margin: auto;
}

.eMed_Dis_Tem_HeadCon {
    background-color: #FAF9F9;
    height: 6vh;
    border-radius: 4px 4px 0px 0px;
}

.eMed_Dis_Layout_selectCon {
    margin: 1vw;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 45vw;
}

.eMed_Dis_Template_NoRecord {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 13vh;
}

.eMed_DisSum_Template_Diet {
    width: 43vw;
}

.eMed_DisSum_Lay_AddTest {
    display: flex;
    flex-direction: row;
    width: 90vw;
    justify-content: space-between;
}

.eMed_Dis_Tem_ActionBtn {
    display: flex;
    flex-direction: row;
}

.eMed_DisSum_Tem_MediTextBox {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 0.5vw;
}

.eMed_Dis_Tem_Tbl {
    height: 70vh;
    width: 100%;
    background-color: white;
    cursor: pointer;
}

.eMed_Dis_Tem_TblHead {
    background-color: #FAF9F9;
}

#eMed_ipsrvConfig_rightTbl {
    width: 29.8vw;
    height: 27vh;
    margin: 0.5vw;
}

.eMed_DisSum_Tem_Nolayout {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 45vh;
}

.eMed_Dis_Sum_Tem_lay_Scroll {
    overflow: scroll;
    height: 53vh;
}

.eMed_Dis_Sum_Tem_BtnGroup {
    display: flex;
    justify-content: center;
}

.eMed_Print_Option_Con {
    height: 64vh;
    padding: 1vw 1vw 1vw 1vw;
}

.eMed_Print_SCon {
    height: 13vw;
    width: 31vw;
}

.eMed_Print_FooCon {
    height: 16vw;
    width: 31vw;
}

.eMed_Pr_SizeImg {
    display: flex;
    flex-direction: row;
    width: 14.5vw;
    justify-content: space-between;
    margin: 1vw;
}

.eMed_Pr_OriImg {
    display: flex;
    flex-direction: row;
    width: 16vw;
    justify-content: space-between;
    margin: 1vw;
}

.eMed_Img_cursor {
    cursor: pointer;
}

.eMed_Print_title {
    height: 4vh;
    display: flex;
    align-items: center;
    padding-left: 1vw;
    background-color: #FAF9F9;
}

.eMed_ABill_Head {
    color: #616161 !important;
    background-color: #F5F8F7 !important;
    font-weight: 600 !important;
    padding: 0.5vw !important;
}

.eMed_ABill_Tbl {
    height: 66.6vh;
}

.eMed_ABill_Tblcell {
    padding: 0.5vw 0vw 0.5vw 0.5vw !important;
}

.eMed_AB_NoRecords {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 60vh;
}

.eMed_AB_NoRecords1 {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 18vh;
}

.eMed_leftTbl_NoRecords {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 30vh;
}

.eMed_AB_leftData {
    height: 60vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.eMed_AB_Left_Tbl {
    height: 55vh;
    overflow: scroll;
}

.eMed_user_conf_container {
    height: 80vh;
}

.eMed_user_conf_header {
    height: 10vh;
    display: flex;
    padding: 1vw;
    justify-content: flex-end;
    align-items: center;
}

.eMed_user_conf_content {
    height: 70vh;
}

.eMed_user_conf_grid {
    width: 92vw;
    margin: auto;
    background-color: white;
}

.eMed_user_conf_img {
    border-right: 2px solid gray;
    margin-right: 1vw;
    padding-right: 1vw;
    position: relative;
    top: 0.2vw;
}

.eMed_edit_img {
    height: 1.6vw;
    width: 1.6vw;
    border-radius: 3px;
}

.eMed_user_btn {
    min-width: 0vw !important;
}

.eMed_new_container {
    height: 80vh;
    display: flex;
}

.eMed_new_left {
    flex: 40vw;
}

.eMed_new_right {
    flex: 60vw;
}

.eMed_new_right_card {
    margin: 0.5vw;
    height: 77.5vh;
    background-color: white;
    border-radius: 5px;
    box-shadow: 0px 0px 1px black;
    padding: 0.5vw 0vw 0vw 0vw;
}

.eMed_new_right_top {
    margin: 0.2vw;
    height: 12vw;
    border-bottom: 1px solid lightgray;
}

.eMed_new_middle {
    margin: 0.2vw;
    height: 33.3vw;
    overflow: scroll;
}

.eMed_new_btn {
    height: 3.3vw;
}

.eMed_new_right_middle {
    /* height: 15vw; */
    margin-left: 0.2vw;
}

.eMed_input_text_box_UI {
    width: 12vw;
}
.eMed_input_text_box {
    margin: 0.5vw 1vw !important;
    width: 15vw;
}

.eMed_input_select_box {
    margin: 0.5vw 2.5vw 0.5vw 1vw;
}

/* .eMed_new_right_bottom{
    height: 17vw;
} */

.eMed_select_text_box {
    margin: 0.5vw 1.2vw 0.5vw 1vw !important;
    width: 13vw;
}

.eMed_select_text_box_ui {
    margin: 0.5vw 1.2vw 0.5vw 1vw !important;
    width: 12.3vw;
}

.eMed_permission_box {
    margin: 0.5vw 1.2vw 0.5vw 1vw !important;
}

#eMed_permission_header {
    font-weight: 600;
    color: #616161;
    font-size: 0.95vw;
}

.eMed_permission_hdrBox {
    height: 4vw;
    border-bottom: 1.5px solid lightgray;
}

#eMed_permission_subheader {
    color: #616161;
    font-size: 0.89vw;
}

.eMed_permission_card {
    height: 25.8vw;
    overflow: scroll;
}

#eMed_permission_checkTxt {
    font-size: 0.89vw;
    font-weight: 600;
    color: #616161;
}

#eMed_permission_Txt {
    font-size: 0.8vw;
    color: #616161;
    margin-left: 1.7vw;
}

#eMed_permission_TimeTxt {
    font-size: 0.8vw;
    color: #616161;
}

.eMed_new_btn_two {
    margin: 0.5vw 0vw 0.5vw 0vw !important;
    text-transform: capitalize !important;
}

.eMed_new_table {
    border: 1px solid lightgray;
}

.eMed_tbl_head {
    font-weight: 600 !important;
    color: #616161 !important;
    background-color: #F5F8F7 !important;
}

.eMed_new_btn {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #F5F8F7;
}

.eMed_new_user_tbl {
    height: 15vw;
    overflow: scroll;
}

.eMed_new_left_content {
    margin: 0.5vw;
    height: 77.5vh;
    background-color: white;
    border-radius: 5px;
    box-shadow: 0px 0px 1px black;
    padding: 0.5vw 0vw 0vw 0vw;
}

.eMed_left_header {
    height: 4vw;
    padding-top: 0.3vw;
}

.eMed_left_content {
    height: 33vw;
}

.eMed_new_user_img {
    display: flex;
    justify-content: center;
    align-items: center;
}

.eMed_new_user {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.eMed_new_image_card {
    height: 12vw;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.eMed_new_profile_ui {
    height: 5.5vw;
    width: 5.5vw;
}
.eMed_new_profile {
    height: 7vw;
    width: 7vw;
}
.eMed_Useradd_btn_ui {
    height: 1vw;
    min-width: 0vw !important;
    width: 1vw;
    position: relative;
    right: 1.5vw;
    top: 4.3vw;
    cursor: pointer;
}

.eMed_add_btn {
    position: relative;
    bottom: 2vw;
    left: 2.3vw;
    cursor: pointer;
}
.eMed_new_img_res_ui {
    width: 5.5vw;
    height: 5.5vw;
    border-radius: 50%;
    object-fit: fill;
}

.eMed_new_img_res {
    width: 7vw;
    height: 7vw;
    border-radius: 50%;
    object-fit: fill;
}

.eMed_new_delete {
    height: 1.5vw;
    width: 1.5vw;
    cursor: pointer;
}

.eMed_new_img_btn {
    min-width: 0vw !important;
    height: 7vw;
    width: 7vw;
    display: flex;
}

.eMed_visibility_icon {
    position: relative;
    right: 3vw;
    top: 1vw;
}

.eMed_delete_user {
    margin: 1vw;
    display: flex;
}

.eMed_delete_img {
    height: 1vw;
    width: 1vw;
    margin-right: 0.5vw;
}

.eMed_top_edit_btn {
    display: flex;
    justify-content: flex-end;
    padding-right: 1vw;
}

.eMed_edit_img_user {
    height: 1.8vw;
    width: 1.8vw;
    border-radius: 5px;
}

.eMed_lock_img_user {
    height: 1.8vw;
    width: 1.8vw;
}

.eMed_bac_user_btn {
    height: 1.8vw;
    width: 1.8vw;
    margin-left: 1vw;
    cursor: pointer;
}

.eMed_mod_card {
    /* border: 1px solid gray; */
    height: 26.8vw;
    overflow: scroll;
    margin: 0vw 1vw;
}

.eMed_list_image {
    height: 1.2vw;
    width: 1.2vw;
    cursor: pointer;
}

.eMed_userAccessRow {
    border: 1px solid rgb(219, 218, 218);
    border-top: 0;
    border-left: 0;
    border-right: 0;
    margin-right: 0.5vw;
}

.eMed_DisSum_Tem_Anaesthsia {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 2vw 1vw 1vw 1vw;
}

.eMed_txt_header {
    font-size: 0.9vw;
    color: #616161;
    font-weight: 600;
}

.eMed_tgleBar_grid {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 7vh;
}

.eMed_tgleBar_grid_action {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 0.7vw;
}

#eMed_tgleBar_grid_txt {
    color: #616161;
}

.eMed_Config_Btn {
    min-width: 0vw !important;
}

.eMed_New_Ip_Btn {
    min-width: 0vw !important;
    text-transform: capitalize !important;
}

.eMed_New_Ip_Popup {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 49vw;
    height: 30vw;
    background-color: white;
    border-radius: 10px;
    overflow: scroll;
}

.eMed_New_OP_Tbl {
    height: 35vw !important;
    overflow: scroll;
}

.eMed_Dis_Layout_selectCon1 {
    margin: 1vw;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 60vw;
}

.eMed_top_edit_btn_ui {
    display: flex;
    height: 2.5vw;
    justify-content: space-between;
    align-items: center ;
    border-bottom: 1px solid lightgray;
}

.eMed_Card_Details{
    font-weight: 600;
}