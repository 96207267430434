:root{
    --bgColor: #F5F8F7;
    --headBorder: #cbbfbf;
    --white: #ffff;
    --headboxShadow: #e3e0e0;
    --error: #F8E8E7;
}
#eMed_IP_Appointment{
    width: 100%;
    height: 100%;
    margin-top: -0vw;
    background-color: var(--bgColor);
}
.eMed_IP_AptHead{
    border-bottom: 1px solid var(--headBorder);
    box-shadow: 0px 2px var(--headboxShadow);
    display: flex;
    flex-direction: row;
    background-color: var(--white);
    justify-content: space-between;
    align-items: center;
    height: 8vh;
}
.eMed_IP_AptDateFlt {
    width: 13vw;
    position: relative;
    top: 0.36vw;
}
.eMed_IP_AptTbl_Status_Icon {
    width: 1.5vw;
    height: 1.5vw;
}
.eMed_Price_Card_Img {
    width: 1vw;
    height: 1vw;
}
.eMed_Price_Card_Icon {
    display: flex;
    flex-direction: row;
    width: 25vw;
    align-items: center;
}
.emedhub_Price_popup_header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    height: 3vw;
    align-items: center;
    border-top-left-radius: 0.5vw;
    border-top-right-radius: 0.5vw;
    outline: none;
    }
.eMed_IP_AptTbl_Status_Icon1 {
    width: 1.6vw;
    height: 1.6vw;
    margin-top: 0.3vw;
}
.eMed_IPAptTbl_Con {
    height: 68vh;
    width: 100%;
    background-color: white;
    cursor: pointer;
}

.eMed_IP_checkoutDiv{
    width:32vw;
    background-color: var(--error);
    height: 4.5vh;
    padding: 0.5vw;
    border-radius: 0.3vw;
    margin-bottom: 0.5vw;
}
.eMed_IP_pending_Icon{
    width: 1.3vw;
    height: 1.3vw;
}
.eMed_reservePop_Con{
    min-height: 35vh;
}
.eMed_reservePop_subDiv{
    width: 65vw;
    display: flex;
    justify-content: space-evenly;
    margin: 1.8vw 0.5vw;
}

#eMed_Bed_Card{
    height: 68vh;
    overflow-y: scroll;
    display: flex;
    flex-wrap: wrap;
}

#eMed_Bed_Reser_Card{
    height: 55vh;
    width: 80vw;
    overflow-y: scroll;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

#eMed_Bed_CardView{
    width: 10.5vw;
    height: 8.5vw;
    margin: 0.5vw;
    display: flex;
}

#eMed_Bed_wardText{
    display: flex;
    flex-direction: row;
    justify-self: center;
    margin-top: 0.8vw;
    width: 7vw;
}

#eMed_Bed_FloorCon{
    width: 1.4vw;
    height: 1.4vw;
    display: flex;
    border-radius: 0.1vw;
    justify-content: center;
    align-content: center;
}

#eMed_Bed_FloorText{
    display: flex;
    flex-direction: row;
    justify-self: center;
    width: 7vw;
}

#eMed_Bed_Circle{
    display: flex;
    justify-content: center;
    width: 100vw;
    align-self: center;
}
#eMed_Bed_Reserve_CardView{
    width: 9vw;
    height: 6.5vw;
    margin: 0.5vw;
    display: flex;
}

#eMed_Bed_Reserve_wardText{
    display: flex;
    flex-direction: row;
    justify-self: center;
    margin-top: 0.6vw;
    width: 5vw;
}

#eMed_Bed_Reserve_FloorText{
    display: flex;
    flex-direction: row;
    justify-self: center;
    width: 5vw;
}
#eMed_Bed_Reserve_FloorCon{
    width: 1vw;
    height: 1vw;
    display: flex;
    border-radius: 0.1vw;
    justify-content: center;
    align-content: center;
}

.eMed_Checkout_popup{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width:35vw;
    height: 25vh;
    background-color: white;
    box-shadow: 24;
    padding: 4;
    border-radius: 10px;
}

.eMed_Emergy_Tag{
    background-color: #ffc0cb !important;
}