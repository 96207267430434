.reports_container {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
    background-color: #F5F8F7;
    height: 75vh;
    max-width: 100vw;
}

.reports_header_card {
    padding: 1vw 0vw 0vw 1vw;
    display: flex;
}

.reports_left {
    display: flex;
    flex: 0.6;
}

.reports_flex_one {
    flex: 0.25;
}

.reports_flex_two {
    flex: 0.25;
}

.reports_flex_three {
    flex: 0.25;
}

.reports_flex_four {
    flex: 0.25;
}

.reports_doctor_left {
    display: flex;
    flex: 0.6;
}

.reports_doctor_flex_one {
    flex: 0.25
}

.reports_doctor_flex_two {
    flex: 0.25
}

.reports_doctor_flex_three {
    flex: 0.25
}

.reports_doctor_flex_four{
    flex: 0.25
}

.reports_doctor_right {
    display: flex;
    flex: 0.4;
    justify-content: flex-end;
    align-items: flex-end;
    margin-right: 1.6vw;
}

.reports_right {
    display: flex;
    flex: 0.4;
    justify-content: flex-end;
    align-items: flex-end;
    margin-right: 1.6vw;
    position: relative;
    cursor: pointer;
}

.report_date_picker {
    margin-right: 1vw;
}

.reports_button {
    margin-right: 1vw;
    border-left: 2px solid gray;
    padding-left: 1vw;
    position: relative;
    top: -0.4vw;
}

.reports_button_two {
    position: relative;
    top: -0.4vw;
}

.reports_table_card {
    /* max-height: 100vh; */
    height: 61vh;
    width: 92vw;
    margin: auto;
    background-color: white;
    overflow: scroll;
    margin-top: 1vw;
}

.reports_font_size {
    font-size: 1.2vw;
    font-weight: 600;
}

.reports_box_one {
    background-color: white;
    height: 5vw;
    width: 12vw;
    box-shadow: 0px 0px 3px gray;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.reports_bill_left {
    display: flex;
    flex: 0.7;
}

.reports_bill_flex_one {
    flex: 0.2
}

.reports_bill_flex_two {
    flex: 0.2;
}

.reports_bill_flex_three {
    flex: 0.2
}

.reports_bill_flex_four {
    flex: 0.2
}

.reports_bill_flex_five {
    flex: 0.2
}

.reports_bill_box_one {
    position: relative;
    right:1vw;
}

.bill_background_pending {
    background-color: #FFE9E9;
    color: #B82121;
}

.bill_background_paid {
    background-color: #ECFFE9;
    color: #1E972A;
}

.paid {
    width: 7vw;
    height: 2vw;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #1E972A;
    background-color: #ECFFE9;
    border-radius: 5px;
}

.pending {
    width: 7vw;
    height: 2vw;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #B82121;
    background-color: #FFE9E9;
    border-radius: 5px;
}
.eMed_bill_print {
    background-color: lightgray;
}
.reports_print {
    height: 2.2vw;
    cursor: pointer;
    position: relative;
    top: -0.5vw;
    right: 0.5vw;
    /* border-left: 2px solid gray; */
    /* padding-left: 0.5vw; */
}

.eMed_rts_upload{
    height: 2.2vw;
    cursor: pointer;
    position: relative;
    top: -0.5vw;
    margin-right: 0.5vw;
}

.eMed_rts_date_pick{
    position: relative;
    top: 0.9vw;
}